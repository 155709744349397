import { Landing } from "@/components/pages/landing";
import type { Activity } from "@lib/activity";
import type { Ticket } from "@lib/ticket";
import type { Config } from "@lib/config/utils";
import { type GenericLandingPage } from "@lib/config/types";

export type Props = {
  activity: Activity;
  config: Config;
  ticket?: Ticket;
};

export function CompletionPage({ activity, config, ticket }: Props) {
  return (
    <Landing
      activity={activity}
      config={config as GenericLandingPage}
      ticket={ticket}
      page="completion"
    />
  );
}
