import { ErrorPreview } from "@/components/pages/error-preview";
import { Preview } from "@/components/pages/preview";
import { ShareablePreview } from "@/components/pages/shareable-preview";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { Config } from "@lib/config/utils";
import { type LandingPageChoice } from "@lib/landing";
import type { Flow } from "@lib/path.server";

export type Props = {
  flow: Flow;
  activity: Activity;
  config: Config;
  encodedActivityId: string;
  flowConsents: ActivityFlowConsents;
};

function isLandingPageChoice(
  page: LandingPageChoice,
): page is LandingPageChoice {
  return [
    "out_of_stock",
    "closed",
    "claimed",
    "landing",
    "completion",
  ].includes(page);
}

export function PreviewPage({
  flow,
  activity,
  config,
  encodedActivityId,
  flowConsents,
}: Props) {
  if (flow.type === "delayed") return;
  if (flow.type === "unsubscribe") return;
  if (flow.preview === "config") {
    if (flow.page === "error" && flow.error) {
      return (
        <ErrorPreview
          type={flow.type}
          activity={activity}
          page={flow.error as LandingPageChoice}
        />
      );
    }
    if (
      typeof flow.page === "number" ||
      (flow.page !== "lock" && isLandingPageChoice(flow.page))
    ) {
      return (
        <Preview
          type={flow.type}
          activity={activity}
          page={flow.page}
          flowConsents={flowConsents}
        />
      );
    }
  } else {
    return (
      <ShareablePreview
        activity={activity}
        config={config}
        encodedActivityId={encodedActivityId}
        type={flow.type}
        flowConsents={flowConsents}
      />
    );
  }
}
