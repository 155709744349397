import { ReviewForm } from "@/components/forms/review/form";
import { Review } from "@/components/pages/review";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { Ticket } from "@lib/ticket";
import type { GenericReview } from "@lib/config/types";
import { type OrderDetails, reviewStepArray } from "@lib/review";

export type ReviewProps = {
  activity: Activity;
  config: GenericReview;
  ticket?: Ticket;
  encodedActivityId: string;
  orderDetails?: OrderDetails;
  flowConsents?: ActivityFlowConsents;
  currentStep?: string;
};

export function ReviewPage({
  activity,
  config,
  ticket,
  encodedActivityId,
  orderDetails,
  flowConsents,
  currentStep,
}: ReviewProps) {
  const stepsArray = reviewStepArray(config.pages);

  if (typeof window === "undefined") return null;

  return (
    <ReviewForm
      encodedActivityId={encodedActivityId}
      ticket={ticket}
      steps={stepsArray}
      currentStep={currentStep ?? "0"}
      blueprint={activity.feedbackBlueprint}
      orderDetails={orderDetails}
    >
      <Review
        activity={activity}
        config={config}
        ticket={ticket}
        flowConsents={flowConsents}
        page={currentStep ?? "0"}
      />
    </ReviewForm>
  );
}
