import { LockedForm } from "@/components/forms/locked/form";
import { Error } from "@/components/pages/error";
import { CompletionPage } from "@/routes/complete";
import { DoubleOptinPage } from "@/routes/double-optin";
import { LandingPage } from "@/routes/landing";
import { PreviewPage } from "@/routes/preview";
import { ReviewPage } from "@/routes/review";
import type { Activity } from "@lib/activity";
import type { Config } from "@lib/config/utils.server";
import type { LandingErrorPage } from "@lib/landing";
import type { Flow } from "@lib/path.server";
import type { Ticket } from "@lib/ticket";
import { Delayed } from "./components/pages/delayed";
import { AlreadyDelayed } from "./components/pages/already-delayed";
import { UnsubscribePage } from "./components/pages/unsubscribe";
import { UnsubscribedPage } from "./components/pages/unsubscribed";
import { NotFound } from "./components/pages/404";
import { ServerError } from "./components/pages/5xx";
import { type OrderDetails } from "@lib/review";
import { type GenericReview } from "@lib/config/types";

type AppProps = {
  activity: Activity;
  config: Config | undefined;
  ticket: Ticket | undefined;
  flow: Flow;
  encodedActivityId: string;
  shortOrderId?: string;
  orderDetails?: OrderDetails;
  currentStep?: string;
};

export function App({
  activity,
  config,
  ticket,
  flow,
  encodedActivityId,
  shortOrderId,
  orderDetails,
  currentStep,
}: AppProps) {
  if (flow.type === "not-found") {
    return <NotFound />;
  }

  if (flow.type === "server-error") {
    return <ServerError />;
  }

  if (!config) {
    if ("error" in flow && flow.error) {
      if (flow.type === "delayed" && flow.error === "already-delayed")
        return <AlreadyDelayed />;

      if (flow.type === "unsubscribe" && flow.error === "unsubscribed")
        return <UnsubscribedPage />;
    }
    if (flow.type === "delayed") {
      return <Delayed ticket={ticket} activity={activity} />;
    }
    if (
      flow.type === "unsubscribe" &&
      flow.page === "landing" &&
      shortOrderId
    ) {
      return <UnsubscribePage shortOrderId={shortOrderId} />;
    }

    return;
  }

  if (flow.preview) {
    const flowConsents =
      flow.type === "claim" ? activity.claimConsents : activity.reviewConsents;
    return (
      <PreviewPage
        flow={flow}
        activity={activity}
        config={config}
        encodedActivityId={encodedActivityId}
        flowConsents={flowConsents}
      />
    );
  }

  if (flow.page === "completion") {
    return (
      <CompletionPage activity={activity} config={config} ticket={ticket} />
    );
  }

  if (flow.type === "claim" && flow.page === "lock") {
    return <LockedForm encodedActivityId={encodedActivityId} />;
  }

  if ("error" in flow && flow.error) {
    if (flow.type === "review" && flow.error === "reviewed") {
      return (
        //Go to the thank you page
        <ReviewPage
          activity={activity}
          config={config as GenericReview}
          ticket={ticket as Ticket}
          encodedActivityId={encodedActivityId}
          orderDetails={orderDetails}
          flowConsents={activity.reviewConsents}
          currentStep={String((config as GenericReview).pages.length - 1)}
        />
      );
    }
    return (
      <Error
        activity={activity}
        config={config}
        page={flow.error as LandingErrorPage}
        type={flow.type}
      />
    );
  }

  if (flow.type === "double_optin") {
    return (
      <DoubleOptinPage
        activity={activity}
        config={config}
        flow={flow}
        encodedActivityId={encodedActivityId}
      />
    );
  }

  if (flow.type === "review") {
    return (
      <ReviewPage
        activity={activity}
        config={config as GenericReview}
        ticket={ticket as Ticket}
        encodedActivityId={encodedActivityId}
        orderDetails={orderDetails}
        flowConsents={activity.reviewConsents}
        currentStep={currentStep}
      />
    );
  }

  return (
    <LandingPage
      activity={activity}
      config={config}
      ticket={ticket}
      encodedActivityId={encodedActivityId}
      flowConsents={activity.claimConsents}
    />
  );
}
